
import { ChevronsUpDownIcon, CreditCardIcon, LoaderIcon, MoreVertical, UserPenIcon } from "lucide-react"

import { Link } from "react-router-dom"
import { Button } from "../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem, DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu"
import { Switch } from "../../../components/ui/switch"
import { cn } from "../../../lib/utils"

export const columns = (onEditUser, toggleUserStatus, isChangingStatus) => [
  {
    accessorKey: "name",
    label: 'نام کاربر',
    header: ({ column }) => {
      return (
        <Button
          className="text-center w-[200px]"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام کاربر
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const {name, id, icon} = row.original;
      return <div className="flex items-center gap-x-2 justify-center">
        <Link to={`#`}>{name}</Link>
      </div>
    },
  },
  {
    accessorKey: "userName",
    label: 'نام کاربری',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام کاربری
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "phoneNumber",
    label: 'شماره تماس',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          شماره تماس
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "email",
    label: 'ایمیل',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          ایمیل
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "isActive",
    label: 'وضعیت (فعال/غیرفعال)',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          وضعیت (فعال/غیرفعال)
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const isActive = row.getValue("isActive")
      const id = row.original.id;
      return isChangingStatus === id ? <LoaderIcon className="w-4 h-5 animate-spin mx-auto" /> :<Switch checked={isActive} onCheckedChange={() => toggleUserStatus(row.original.id)} />
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const bank = row.original

      return (
        <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild className={cn(!!onEditUser ? '' : 'hidden')}>
            <Button disabled={!!!onEditUser} variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreVertical className="!m-0 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onEditUser(bank.id)}
            >
              <UserPenIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده و ویرایش</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              // onClick={() => onGetBankDetails(bank.id)}
            >
              <CreditCardIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده آمار</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
]
