import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { z } from 'zod';
import { getUserInfo } from '../../api';
import { FormField } from '../../components/Customers/form-field';
import { Button } from '../../components/ui/button';
import { Skeleton } from '../../components/ui/skeleton';
import usePostAdminBankAccount from '../../hooks/admin-local-bank-account';
import { userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

const getBankSchema = () =>
    z.object({
      id: z.string(),
      iban: z.string()
      .regex(/^(?:IR)(?=.{24}$)[0-9]*$/gi, 'شماره شبا نامعتبر است'),
      note: z.string().optional(),
    });

function EditAdminLocalBankAccount() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [errors, setErrors] = useState({});
    const accountId = useMemo(() => searchParams.get('accountId'), [searchParams]);

    const {data, isFetching} = usePostAdminBankAccount.useGetAdminBankDetails({accountId});
    const usePostAdminBankAccountMutation = usePostAdminBankAccount.useUpdateAdminBankAccount()
    
    const [formData, setFormData] = useState({
        id: "",
        iban: "",
        note: "",
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const schema = getBankSchema();
        const fieldValidation = schema.shape[name].safeParse(value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldValidation.success ? undefined : fieldValidation.error.errors[0].message,
        }));
    };

    const validate = useCallback(() => {
        const schema = getBankSchema();
        const validation = schema.safeParse(formData);
        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((error) => {
                newErrors[error.path[0]] = error.message;
            });
            setErrors(newErrors);
            return false;
        }
        setErrors({});
        return true;
    }, [formData]);

    React.useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }
    }, [dispatch, navigate])

    const onSubmit = useCallback(async (event) => {
        event.preventDefault()

        if (!validate()) return;
        const res = await usePostAdminBankAccountMutation.mutateAsync(formData);
        if (res.isSuccess)
            navigate('/local-bank-account-list');
    }, [formData, navigate, validate, usePostAdminBankAccountMutation]);

    useEffect(() => {
        if (data) {
            setFormData({
                id: data.id || "",
                iban: data.iban || "",
                note: data.note ||"",
            });
        }
    }, [data])

    return (
        
        <div>
            <Navbar displayMenu />
            <div className="w-full flex items-start">
                <div className="hidden md:block">
                    <Sidebar />
                </div>
                <div className="w-full p-8 max-md:p-4">
                    <h2 className="text-xl font-bold text-gray-800 mb-12">افزودن شماره حساب شرکت</h2>
                    <div className="w-full border border-slate-200 max-md:border-none rounded-lg overflow-hidden p-4">
                        <form onSubmit={onSubmit} className="flex flex-col items-start gap-y-4 max-w-4xl mx-auto">
                            <div className="flex max-md:flex-col items-start w-full gap-x-12">
                                {isFetching ? <>
                                    <Skeleton className='w-full h-10' />
                                    <Skeleton className='w-full h-10' />
                                </> :
                                <>
                                    <FormField
                                        id="iban"
                                        label="شماره شبا"
                                        name="iban"
                                        value={formData.iban}
                                        onChange={handleInputChange}
                                        error={errors.iban}
                                        description="شماره شبا"
                                        required
                                    />
                                    <FormField
                                        id="note"
                                        label="یادداشت"
                                        name="note"
                                        value={formData.note}
                                        onChange={handleInputChange}
                                        error={errors.note}
                                        description="یادداشت"
                                    />
                                </>}
                            </div>
                            <Button className='!m-0' type="submit" disabled={usePostAdminBankAccountMutation.isPending}>
                                ویرایش اطلاعات
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditAdminLocalBankAccount;