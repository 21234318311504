import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeUserState, getUserInfo, getUserList } from '../../api';
import { notification, userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

import { useQuery, useQueryClient } from "@tanstack/react-query";
import { columns } from './components/columns';
import { DataTable } from './components/data-table';
import TableToolbar from './components/table-toolbar';

function UserList() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [name, setName] = useState("")
    const [page, setPage] = useState(params.get("page") || 1)
    const [pageSize, setPageSize] = useState(params.get("pageSize") || 10)
    const [isChangingStatus, setIsChangingStatus] = useState('');

    const { data } = useQuery({
        queryKey: ["users", params.get("name") || '', page, pageSize],
        queryFn: () => getUserList(params.get("name") || '', page, pageSize),
        keepPreviousData: true,
        staleTime: 1000 * 60 * 5,
        refetchOnWindowFocus: false,
    })

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }
    }, [])

    const handleChangeUserStatus = useCallback(async (id) => {
        setIsChangingStatus(id);
        const response = await changeUserState(id)
        if (response.data.isSuccess) {
            setIsChangingStatus('');
            dispatch(notification({ message: 'وضعیت با موفقیت تغییر کرد', type: 'suc', id: new Date().getSeconds() }))
            queryClient.invalidateQueries({ queryKey: ['users', params.get("name") || '', page, pageSize] });
        } else {
            setIsChangingStatus('');
            dispatch(notification({ message: 'خطا در تغییر وضعیت', type: 'err', id: new Date().getSeconds() }))
        }


    }, [dispatch, queryClient, page, pageSize, params])

    const updateURLParams = useCallback((_name = name, pageValue = page, pageSizeValue = pageSize) => {
        const params = new URLSearchParams({
            name: _name,
            page: pageValue.toString(),
            pageSize: pageSizeValue.toString(),
        });
        navigate(`?${params.toString()}`, { replace: true });
    }, [name, page, pageSize, navigate]);

    const handlePageSizeChange = useCallback((value) => {
        setPageSize(value);
        setPage(1);
        updateURLParams(name, 1, value);
    }, [name, updateURLParams]);

    const handlePageChange = (newPage) => {
        setPage(newPage);
        updateURLParams(name, newPage, pageSize);
    };

    const handleSubmitFilters = useCallback((e) => {
        e.preventDefault();
        setPage(1);
        updateURLParams(name, 1, pageSize);
    }, [name, pageSize, updateURLParams]);

    const removeFilter = useCallback((filterKey) => {
        const params = new URLSearchParams(location.search);
        params.delete(filterKey);

        switch (filterKey) {
            case "name":
                setName("");
                break;
            default:
                break;
        }

        navigate(`?${params.toString()}`, { replace: true });
    }, [navigate, location.search]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const nameFromParams = params.get("name") || "";
        const pageFromParams = parseInt(params.get("page")) || 1;
        const pageSizeFromParams = parseInt(params.get("pageSize")) || 10;

        setName(nameFromParams);
        setPage(pageFromParams);
        setPageSize(pageSizeFromParams);

    }, [location.search]);

    return (
        <div>
            <Navbar displayMenu />
            <div className="w-full flex items-start">
                <div className="hidden md:block">
                    <Sidebar />
                </div>
                <div className="w-full p-8 max-md:p-4 overflow-x-auto">
                    <h2 className="text-xl font-bold text-gray-800 mb-12">فهرست  کاربران</h2>
                    <div className="w-full bg-slate-100 border border-slate-200 rounded-lg overflow-hidden">
                        <TableToolbar
                            onSearch={handleSubmitFilters}
                            name={name}
                            setName={setName}
                            onRemoveFilter={removeFilter}
                        />

                        <div className="rounded-lg overflow-hidden border-t border-gray-300">
                            <DataTable
                                columns={columns}
                                data={data?.data.data.list || []}
                                page={page}
                                pageSize={pageSize}
                                totalPage={data?.data.data.totalPage}
                                onPageChange={handlePageChange}
                                loading={false}
                                toggleUserStatus={handleChangeUserStatus}
                                onPageSizeChange={handlePageSizeChange}
                                isChangingStatus={isChangingStatus}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

export default UserList;