
import { ChevronsUpDownIcon, CreditCardIcon, HistoryIcon, MoreVerticalIcon, Trash2Icon, User2Icon, UserCheckIcon, UserPenIcon, UserXIcon } from "lucide-react"

import { Link } from "react-router-dom"
import { Avatar, AvatarFallback, AvatarImage } from "../../../components/ui/avatar"
import { Button } from "../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem, DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu"
import { cn } from "../../../lib/utils"

export const columns = (onEditUser, onDeleteUser, onGetCustomerListInfo, onGetPaymentHistory, onBlockUser) => [
  {
    accessorKey: "name",
    label: 'نام مشتری',
    header: ({ column }) => {
      return (
        <Button
          className="text-center w-[200px]"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام مشتری
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const avatar = row.original.nationalCardImage
      const userId = row.original.id
      const value = row.getValue('name')
      return <div className="flex items-center gap-x-2">
        <Avatar>
          <AvatarImage src={`${process.env.REACT_APP_BASE_FILE_URL}${avatar}`} />
          <AvatarFallback className='text-gray-800'><User2Icon className="text-slate-400 mx-auto" /></AvatarFallback>
        </Avatar>
        <Link to={`/edit-customer?userid=${userId}`}>{value}</Link>
      </div>
    },
  },
  {
    accessorKey: "nationalCode",
    label: 'کد ملی مشتری',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          کد ملی مشتری
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "isExpired",
    label: 'وضعیت کارت ملی',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          وضعیت کارت ملی
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const isExpired = row.getValue("isExpired")
      const value = isExpired ? 'نامعتبر' : 'معتبر'
      return <div className={cn(isExpired ? 'text-red-500' : 'text-green-500', 'text-center font-medium')}>{value}</div>
    },
  },
  {
    accessorKey: "mobileNumber",
    label: 'شماره موبایل',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          شماره موبایل
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
  },
  {
    accessorKey: "isForeign",
    label: 'خارجی',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          خارجی
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const isForeign = row.getValue("isForeign")
      const value = isForeign ? 'بله' : 'خیر'
      return <div className={cn(isForeign ? 'text-green-500' : 'text-amber-600', 'text-center font-medium')}>{value}</div>
    },
  },
  {
    accessorKey: "isBlocked",
    label: 'مسدود',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          مسدود
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const isBlocked = row.getValue("isBlocked")
      const value = isBlocked ? 'بله' : 'خیر'
      return <div className={cn(isBlocked ? 'text-green-500' : 'text-amber-600', 'text-center font-medium')}>{value}</div>
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const customer = row.original

      return (
        <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild className={cn(!!onEditUser ? '' : 'hidden')}>
            <Button disabled={!!!onEditUser} variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreVerticalIcon className="!m-0 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onEditUser(customer.id)}
            >
              <UserPenIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده و ویرایش</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onGetCustomerListInfo(customer.id)}
            >
              <CreditCardIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده حساب‌های بانکی</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onGetPaymentHistory(customer.id)}
            >
              <HistoryIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800"> تاریخچه پرداختی</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onBlockUser(customer)}
            >
              {
                customer.isBlocked ?
                  <UserCheckIcon className="me-2 w-3.5 h-3.5" /> :
                  <UserXIcon className="me-2 w-3.5 h-3.5" />
              }
              <span className="text-gray-800">
                {customer.isBlocked ? 'رفع مسدودیت' : 'مسدود کردن'}
              </span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-red-500 group cursor-pointer"
              onClick={() => onDeleteUser(customer.id)}>
              <Trash2Icon className="me-2 w-3.5 h-3.5" />
              <span className="text-red-500 group-hover:text-gray-800">حذف</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
]
