import { useQuery } from "@tanstack/react-query";
import { MenuIcon } from "lucide-react";
import { memo, useMemo } from "react";
import { getBanks } from "../../api";
import { Avatar, AvatarFallback, AvatarImage } from "../../components/ui/avatar";
import { Sheet, SheetContent, SheetTrigger } from "../../components/ui/sheet";
import { Skeleton } from "../../components/ui/skeleton";
import { cn } from "../../lib/utils";
import Sidebar from "../Sidebar";
import styles from "./style.module.scss";

function Navbar({ displayMenu = false }) {

  const {isLoading, data} = useQuery({
    queryKey: ['active-banks'],
    queryFn: () => getBanks('', 1, 10),
  })

  const activeBanks = useMemo(() => data?.data?.data?.list?.filter(bank => bank.isActive), [data?.data?.data?.list])

  return (
    <div className={cn(styles.navbar, 'overflow-x-hidden flex items-center px-2 max-md:justify-between')}>
      {displayMenu ? (
        <Sheet>
          <SheetTrigger className="block md:hidden" asChild>
            <MenuIcon className="w-6 h-6 shrink-0 text-white" />
          </SheetTrigger>
          <SheetContent className="w-max bg-white pt-10">
            <Sidebar />
          </SheetContent>
        </Sheet>
      ) : null}
      <div className='flex items-center gap-x-6 max-md:gap-x-2'>
        {activeBanks?.map((bank) => (
          <div className="flex items-center space-x-2 rtl:space-x-reverse flex-grow">
          {isLoading ? <Skeleton className="w-8 h-8 aspect-auto rounded-full" /> : <Avatar className="w-8 h-8">
            <AvatarImage className="bg-white" src={`${process.env.REACT_APP_BASE_FILE_URL}/${bank?.icon}`} alt={bank?.name} />
            <AvatarFallback className='!text-gray-800'>{bank?.name?.substring(0, 1)}</AvatarFallback>
          </Avatar>}
          <div className="max-md:hidden">
            {isLoading ? <Skeleton className="w-20 h-4" /> : <h2 className="text-xs text-white font-medium">{bank?.name}</h2>}
          </div>
        </div>
        ))}
      </div>
    </div>
  );
}

export default memo(Navbar);
