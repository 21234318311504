import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useDispatch } from 'react-redux';
import * as Api from '../api';
import { notification } from '../state/action';


export const BankAccountKeys = {
    AdminBankAccounts: ['AdminBankAccounts'],
};

const useGetAdminBankAccount = (params, options) => {
    const query = useQuery({
        queryKey: [...BankAccountKeys.AdminBankAccounts, params],
        queryFn: Api.getAdminLocalBankAccountList,
        ...options
    });
    return query;
}

const useGetAdminBankDetails = (params, options) => {
    const query = useQuery({
        queryKey: [...BankAccountKeys.AdminBankAccounts, params],
        queryFn: () => Api.getAdminLocalBankAccountDetails(params),
        ...options
    });
    return query;
}

const usePostAdminBankAccount = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: Api.postAdminLocalBankAccount,
        onError: (e) => dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: new Date().getSeconds() })),
        onSuccess: () => {
            dispatch(notification({ message: 'شماره حساب با موفقیت افزوده شد', type: 'suc', id: new Date().getSeconds() }))
            queryClient.invalidateQueries(BankAccountKeys.AdminBankAccounts);
        },
    });
    return mutation;
};

const useUpdateAdminBankAccount = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: Api.updateAdminLocalBankAccount,
        onError: (e) => dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: new Date().getSeconds() })),
        onSuccess: () => {
            dispatch(notification({ message: 'شماره حساب با موفقیت ویرایش شد', type: 'suc', id: new Date().getSeconds() }))
            queryClient.invalidateQueries(BankAccountKeys.AdminBankAccounts);
        },
    });
    return mutation;
};

const useDeleteAdminBankAccount = () => {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();
    const mutation = useMutation({
        mutationFn: Api.deleteAdminLocalBankAccount,
        onError: (e) => dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: new Date().getSeconds() })),
        onSuccess: () => {
            dispatch(notification({ message: 'شماره حساب با موفقیت حذف شد', type: 'suc', id: new Date().getSeconds() }))
            queryClient.invalidateQueries(BankAccountKeys.AdminBankAccounts);
        },
    });
    return mutation;
};

export default {
    useGetAdminBankAccount,
    useGetAdminBankDetails,
    usePostAdminBankAccount,
    useUpdateAdminBankAccount,
    useDeleteAdminBankAccount
}