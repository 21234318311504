
import { ChevronsUpDownIcon, CopyIcon, MoreVertical, TrashIcon, UserPenIcon } from "lucide-react"

import { Link } from "react-router-dom"
import { Avatar, AvatarFallback, AvatarImage } from "../../../components/ui/avatar"
import { Button } from "../../../components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem, DropdownMenuTrigger
} from "../../../components/ui/dropdown-menu"
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "../../../components/ui/tooltip"
import { cn } from "../../../lib/utils"

export const columns = (onEditBank, onDeleteAccount, onCopyIban, copyIbanIdSuccess) => [
  {
    accessorKey: "bankName",
    label: 'نام بانک',
    header: ({ column }) => {
      return (
        <Button
          className="text-center w-max"
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          نام بانک
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const { bankName, id, bankIconFile } = row.original;
      return <div className="flex items-center justify-center gap-x-2">
        <Avatar>
          <AvatarImage src={`${process.env.REACT_APP_BASE_FILE_URL}/${bankIconFile}`} />
          <AvatarFallback className='text-gray-800'>{bankName.substring(0, 1)}</AvatarFallback>
        </Avatar>
        <Link to={`/edit-local-bank-account?accountId=${id}`}>{bankName}</Link>
      </div>
    },
  },
  {
    accessorKey: "iban",
    label: 'شماره شبا',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          شماره شبا
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const { iban } = row.original;
      if (!iban) return '-';
      return <>
        <TooltipProvider>
          <Tooltip open={copyIbanIdSuccess === iban}>
            <TooltipTrigger asChild>
              <button type='button' onClick={() => onCopyIban(iban)} className="w-max mx-auto inline-flex items-center justify-center gap-x-2 cursor-pointer">
                <span className="!text-gray-800">{iban}</span>
                <CopyIcon className="w-3 h-3 cursor-pointer" />
              </button>
            </TooltipTrigger>
            <TooltipContent>
              <span className="!text-gray-800">کپی شد</span>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </>
    },
  },
  {
    accessorKey: "note",
    label: 'یادداشت',
    header: ({ column }) => {
      return (
        <Button
          variant="ghost"
          onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
        >
          یادداشت
          <ChevronsUpDownIcon className="mr-2 h-4 w-4" />
        </Button>
      )
    },
    cell: ({ row }) => {
      const { note } = row.original;
      if (!note) return '-';
      return <>
        <TooltipProvider>
          <Tooltip >
            <TooltipTrigger asChild>
                <span className="!text-gray-800 inline-block w-[320px]">{note}</span>
            </TooltipTrigger>
            <TooltipContent>
              <span className="!text-gray-800">{note}</span>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </>
    },
  },
  {
    id: "actions",
    cell: ({ row }) => {
      const { id } = row.original

      return (
        <DropdownMenu dir="rtl">
          <DropdownMenuTrigger asChild className={cn(!!onEditBank ? '' : 'hidden')}>
            <Button disabled={!!!onEditBank} variant="ghost" className="h-8 w-8 p-0">
              <span className="sr-only">Open menu</span>
              <MoreVertical className="!m-0 h-4 w-4" />
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onEditBank(id)}
            >
              <UserPenIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">مشاهده و ویرایش</span>
            </DropdownMenuItem>
            <DropdownMenuItem className="text-gray-800 cursor-pointer"
              onClick={() => onDeleteAccount(id)}
            >
              <TrashIcon className="me-2 w-3.5 h-3.5" />
              <span className="text-gray-800">حذف</span>
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      )
    },
  },
]
