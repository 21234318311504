import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addUser, getRolesList, getUserInfo } from '../../api';
import { FormField } from "../../components/Customers/form-field";
import { Button } from "../../components/ui/button";
import { Label } from "../../components/ui/label";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../components/ui/select";
import { getPanelUserSchema, getRoleSchema } from '../../lib/schemas';
import { notification, userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';


function AddCustomer() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [roleId, setRoleId] = useState();
    const [formData, setFormData] = useState({
        name: "",
        userName: "",
        password: "",
        repassword: "",
        email: "",
        roleId: "",
    });

    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);

    const [roles, setRoles] = useState();
    const nowDate = new Date();


    const validate = () => {
        const schema = getPanelUserSchema();
        const validation = schema.safeParse(formData);
        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((error) => {
                newErrors[error.path[0]] = error.message;
            });
            setErrors(newErrors);
            return false;
        }
        setErrors({});
        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const schema = getRoleSchema();
        const fieldValidation = schema.shape[name].safeParse(value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldValidation.success ? undefined : fieldValidation.error.errors[0].message,
        }));
    };

    const handleRoleChange = (roleId) => {
        setFormData((prevData) => ({
            ...prevData,
            roleId,
        }));
    };

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))

                    getRolesList("", 1, 100)
                        .then(response2 => {
                            setRoles(response2.data.data.list);
                            setRoleId(response2.data.data.list[0]["id"])
                        })
                        .catch(e2 => {
                            dispatch(notification({ message: e2.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
                        })
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }

    }, [])

    const submitCustomer = (e) => {
        e.preventDefault();
        if (!validate()) return;
        setIsDisabled(true);
        const { name, userName, password, repassword, email, roleId } = formData;
        if (email.indexOf("@") > -1 && email.indexOf(".") > -1) {
            if (password === repassword) {
                addUser(name, userName, password, email, roleId)
                    .then(response => {
                        dispatch(notification({ message: 'کاربر با موفقیت افزوده شد', type: 'suc', id: nowDate.getSeconds() }))
                        navigate('/users-list');
                    })
                    .catch(e => {
                        dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
                    })
            } else {
                dispatch(notification({ message: 'کلمه‌های عبور همخوانی ندارند', type: 'err', id: nowDate.getSeconds() }))
            }
        } else {
            dispatch(notification({ message: 'فرمت ایمیل نادرست است', type: 'err', id: nowDate.getSeconds() }))
        }
    }

    const assignRoleToUser = () => {
        const currentRoleId = document.getElementById("roleTag").value;
        setRoleId(currentRoleId);
    }

    const navToAddRole = () => {
        navigate('/add-role');
    }


    return (
        <div>
            <Navbar displayMenu />
            <div className="w-full flex items-start">
                <div className="hidden md:block">
                    <Sidebar />
                </div>

                <div className="w-full p-8 max-md:p-4">
                    <h2 className="text-xl font-bold text-gray-800 mb-12">افزودن نقش</h2>
                    <div className="w-full border border-slate-200 max-md:border-none rounded-lg overflow-hidden p-4">
                        <form onSubmit={submitCustomer} className="flex flex-col items-start gap-y-4 max-w-5xl mx-auto">
                            <div className="flex max-md:flex-col items-start w-full gap-x-12">
                                <FormField
                                    dir='auto'
                                    id="name"
                                    label="نام"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    error={errors.name}
                                    description="نام"
                                    required
                                />

                                <FormField
                                    dir='ltr'
                                    id="userName"
                                    label="نام کاربری"
                                    name="userName"
                                    value={formData.userName}
                                    onChange={handleInputChange}
                                    error={errors.userName}
                                    description="نام کاربری"
                                    required
                                />
                            </div>

                            <div className="flex max-md:flex-col items-start w-full gap-x-12">
                                <FormField
                                    dir='ltr'
                                    id="password"
                                    label="رمز عبور"
                                    name="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    error={errors.password}
                                    description="رمز عبور"
                                    required
                                />

                                <FormField
                                    dir='ltr'
                                    id="repassword"
                                    label="تکرار رمز عبور"
                                    name="repassword"
                                    value={formData.repassword}
                                    onChange={handleInputChange}
                                    error={errors.repassword}
                                    description="تکرار رمز عبور"
                                    required
                                />
                            </div>

                            <div className="flex max-md:flex-col items-start w-full gap-x-12">
                                <FormField
                                    dir='ltr'
                                    id="email"
                                    label="ایمیل"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    error={errors.email}
                                    description="ایمیل"
                                    required
                                />

                                <div className="grid grid-cols-1 gap-4 mb-4 w-full">
                                    <Label className="inline-block mb-1 !mr-0" htmlFor={roleId}>
                                        نقش
                                        <span className="text-red-500">*</span>
                                    </Label>
                                    <Select
                                        dir="rtl"
                                        onValueChange={handleRoleChange}
                                        value={formData.roleId}
                                    >
                                        <SelectTrigger id='roleId' name='roleId' className="w-full !m-0 [&>span]:!text-gray-800 [&>svg]:!m-0 max-md:w-full">
                                            <SelectValue placeholder="انتخاب نقش" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {roles ? roles.map((role) => (
                                                <SelectItem className="[&>span]:!text-gray-800" value={role.id}>
                                                    {role.name}
                                                </SelectItem>
                                            )) : ""}

                                        </SelectContent>
                                    </Select>
                                </div>

                            </div>
                            <Link to="/add-role" className="text-blue-500 mr-auto">افزودن نقش</Link>
                            <Button type="submit" className='w-[200px] max-lg:w-full !m-0' disabled={isDisabled}>
                                ثبت
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddCustomer;