import axios from "axios";

export const login = (username, password) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    username,
    password,
  };

  return axios.post(`${baseURL}/AdminProfile/Login`, reqObj);
};

export const getUserInfo = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  return axios.get(`${baseURL}/AdminProfile/ShowUserProfile`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateProfile = (userName, name, email) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    name: name,
    userName: userName,
    email: email,
  };

  return axios.post(`${baseURL}/AdminProfile/EditProfile`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCostomerList = (
  FirstFaName,
  LastFaName,
  FirstEngName,
  LastEngName,
  NationalCode,
  mobileNumber,
  isForeigner,
  isBlocked,
  HasNationalCardImage = null,
  page,
  pageSize
) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    FirstFaName,
    LastFaName,
    FirstEngName,
    LastEngName,
    NationalCode,
    MobileNumber: mobileNumber,
    IsForign: isForeigner,
    IsBlocked: isBlocked,
    HasNationalCardImage,
    page: page,
    pageSize: pageSize,
  };

  return axios.get(`${baseURL}/AdminCustomer/CustomerList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getCostomerListForExport = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  return axios.get(`${baseURL}/AdminCustomer/CustomerListForExportExcel`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addCustomer = (
  name,
  engName,
  address,
  zipCode,
  nationalCode,
  nationalCodeExpirationDate,
  nationalCardImage,
  nationalCardImageBack,
  mobileNumber,
  isForeign,
  birthDate,
  expirationDate
) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    name: name,
    engName: engName,
    address: address,
    zipCode,
    nationalCode: nationalCode,
    nationalCodeExpirationDate: nationalCodeExpirationDate,
    nationalCardImage: nationalCardImage,
    nationalCardImageBack: nationalCardImageBack,
    mobileNumber: mobileNumber,
    isForeign: isForeign,
    birthDate,
    expirationDate
  };

  return axios.post(`${baseURL}/AdminCustomer/CreateCustomer`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const PostCreateListOfCustomers = async (params) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const res = await axios({
    method: "post",
    url: `${baseURL}/AdminCustomer/CreateListOfCustomers`,
    data: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const GetPendingCustomer = async () => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const res = await axios({
    method: "get",
    url: `${baseURL}/AdminCustomer/ShowPendingCustomer`,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const PostConfirmCustomers = async (params) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const res = await axios({
    method: "post",
    url: `${baseURL}/AdminCustomer/ConfirmCustomer`,
    data: params,
    headers: {
      Authorization: `Bearer ${token}`,
      'content-type': 'application/json'
    },
  });
  return res.data;
};

export const UploadImagesFiles = async (params) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const res = await axios({
    method: "post",
    url: `${baseURL}/AdminCustomer/UploadImageFiles`,
    data: params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};

export const getCostomer = (id) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Id: id,
  };

  return axios.get(`${baseURL}/AdminCustomer/ShowCustomer`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateCustomer = (
  id,
  name,
  engName,
  address,
  zipCode,
  nationalCode,
  nationalCodeExpirationDate,
  nationalCardImage,
  nationalCardImageBack,
  mobileNumber,
  isForeign,
  birthDate,
  expirationDate
) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    id: id,
    name: name,
    engName: engName,
    address: address,
    zipCode,
    nationalCode: nationalCode,
    nationalCodeExpirationDate: nationalCodeExpirationDate,
    nationalCardImage: nationalCardImage,
    nationalCardImageBack: nationalCardImageBack,
    mobileNumber: mobileNumber,
    isForeign,
    birthDate,
    expirationDate
  };

  return axios.post(`${baseURL}/AdminCustomer/UpdateCustomer`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRolesList = (name, page, pageSize) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    RoleName: name,
    Page: page,
    PageSize: pageSize,
  };

  return axios.get(`${baseURL}/AdminRole/RoleList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getRole = (id) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Id: id,
  };

  return axios.get(`${baseURL}/AdminRole/ShowRole`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const permissionsList = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  return axios.get(`${baseURL}/AdminRole/PermissionList`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const createRole = (name, persianName) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    name: name,
    persianName: persianName,
  };

  return axios.post(`${baseURL}/AdminRole/CreateRole`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const assignPerToRole = (roleId, pers) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    roleId: roleId,
    permissions: pers,
  };

  return axios.post(`${baseURL}/AdminRole/AssignPermissionToRole`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getUserList = (Name, page, pageSize) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Name: Name,
    pageSize: pageSize,
    page: page,
  };

  return axios.get(`${baseURL}/AdminUser/UserList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addUser = (name, userName, password, email, roleId) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    name: name,
    userName: userName,
    password: password,
    email: email,
    roleId: roleId,
  };

  return axios.post(`${baseURL}/AdminUser/RegisterUser`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const changeUserState = (id) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;

  return axios.post(`${baseURL}/AdminUser/ChanegUserState?Id=${id}`, "", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getSettings = (Name, page, pageSize) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Name: Name,
    pageSize: pageSize,
    page: page,
  };

  return axios.get(`${baseURL}/AdminSetting/SettingList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const showSetting = (id) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Id: id,
  };

  return axios.get(`${baseURL}/AdminSetting/ShowSetting`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateSetting = (id, value) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    id: id,
    value: value,
  };

  return axios.post(`${baseURL}/AdminSetting/UpdateSetting`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBanks = (Name, page, pageSize) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Name: Name,
    PageSize: pageSize,
    Page: page,
  };

  return axios.get(`${baseURL}/AdminBank/BankList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const changeBankState = (id) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;

  return axios.post(`${baseURL}/AdminBank/ChanegBankState?Id=${id}`, "", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateBankBalance = (id) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;

  return axios.post(`${baseURL}/AdminBank/UpdateBankBalance?Id=${id}`, "", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const showBank = (id) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Id: id,
  };

  return axios.get(`${baseURL}/AdminBank/ShowBank`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addBank = (name, transactionLimit) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    name: name,
    transactionLimit: transactionLimit,
  };

  return axios.post(`${baseURL}/AdminBank/CreateBank`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const updateBank = (id, name, transactionLimit) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    id,
    name,
    transactionLimit,
  };

  return axios.post(`${baseURL}/AdminBank/UpdateBank`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const getBankDetails = (id) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    Id: id,
  };

  return axios.get(`${baseURL}/AdminBank/BankDetail`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const addTransaction = (transactionFile) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    transactionFile: transactionFile,
  };

  return axios.post(`${baseURL}/AdminTransaction/CreateTransaction`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const TransactionList = (
  FromDate,
  ToDate,
  TransactionStatus,
  CustomerNationalCode,
  BankId,
  Page,
  PageSize
) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    FromDate: FromDate,
    ToDate: ToDate,
    TransactionStatus: TransactionStatus,
    CustomerNationalCode: CustomerNationalCode,
    BankId: BankId,
    Page: Page,
    PageSize: PageSize,
  };

  return axios.get(`${baseURL}/AdminTransaction/TransactionList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const ConfirmTransaction = () => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;

  return axios.post(`${baseURL}/AdminTransaction/ConfirmTransaction`, "", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const uploadFile = (file, fileName) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    file: file,
    fileName: fileName,
  };

  return axios.post(`${baseURL}/AdminFile/UploadFile`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const assignMenuToRole = (roleId, menus) => {
  const menuIds = menus.map((menu) => menu.id);
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    roleId: roleId,
    menuIds,
  };

  return axios.post(`${baseURL}/AdminMenu/AssignMenuToRole`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteTransaction = (id) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;

  return axios.post(
    `${baseURL}/AdminTransaction/DeleteTransaction?Id=${id}`,
    "",
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const TransactionsHistoryList = (PageSize, Page, type) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    PageSize: PageSize,
    Page: Page,
    type: type,
  };

  return axios.get(`${baseURL}/AdminTransactionHistory/HistoryList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const banksDailyReport = (date) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    date: date,
  };

  return axios.post(`${baseURL}/AdminBank/BankDailyReport`, reqObj, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const deleteCustomer = (id) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;

  return axios.post(`${baseURL}/AdminCustomer/DeleteCustomer?Id=${id}`, "", {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const banCustomer = (id, isBlocked) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const body = {
    customerId: id,
    isBlocked,
  };

  return axios.post(`${baseURL}/AdminCustomer/ChangeCustomerBlockState`, body, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const MenuListApi = () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");
  const reqObj = {
    PageSize: 100,
    Page: 1,
  };

  return axios.get(`${baseURL}/AdminMenu/MenuList`, {
    params: reqObj,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const GetCustomerName = (isbn) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    Iban: isbn,
  };
  return axios.get(`${baseURL}/AdminCustomer/GetCustomerName`, {
    params: reqObj,
  });
};

export const getCustomerAccountInfo = (id) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  return axios.get(
    `${baseURL}/AdminCustomer/CustomerAccountInfoList?Id=${id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const getCustomerPaymentHistory= (id, pageSize, page) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  return axios.get(
    `${baseURL}/AdminCustomer/${id}/TransactionList?pageSize=${pageSize}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const addBankAccountToCustomer = (
  customerId,
  sheba,
  customerName
) => {
  const token = sessionStorage.getItem("access_token");
  const baseURL = process.env.REACT_APP_BASE_URL;
  const reqObj = {
    customerId: customerId,
    sheba: sheba,
    customerName: customerName,
  };

  return axios.post(
    `${baseURL}/AdminCustomer/AddBankAccountToCustomer`,
    reqObj,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const deleteBankAccountToCustomer = (bankAccountId) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  return axios.post(
    `${baseURL}/AdminCustomer/DeleteCustomerBankAccountInfo?BankAccountId=${bankAccountId}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const getAdminLocalBankAccountList = async () => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  const res = await axios.get(`${baseURL}/AdminLocalBankAccount/list`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

export const getAdminLocalBankAccountDetails = async (params) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  const res = await axios.get(`${baseURL}/AdminLocalBankAccount/Detail?id=${params.accountId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data.data;
};

export const postAdminLocalBankAccount = async (params) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  const res = await axios.post(
    `${baseURL}/AdminLocalBankAccount/create`,
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const updateAdminLocalBankAccount = async (params) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  const res = await axios.post(
    `${baseURL}/AdminLocalBankAccount/update?id=${params.id}`,
    params,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const deleteAdminLocalBankAccount = async (params) => {
  const baseURL = process.env.REACT_APP_BASE_URL;
  const token = sessionStorage.getItem("access_token");

  const res = await axios.post(
    `${baseURL}/AdminLocalBankAccount/delete?id=${params.id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
