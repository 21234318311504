import { XIcon } from 'lucide-react';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { assignMenuToRole, assignPerToRole, createRole, getRolesList, getUserInfo, MenuListApi, permissionsList } from '../../api';
import { FormField } from '../../components/Customers/form-field';
import { Button } from '../../components/ui/button';
import { ScrollArea } from '../../components/ui/scroll-area';
import { getRoleSchema } from '../../lib/schemas';
import { notification, userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';
import MenuListDialog from './components/menu-list.dialog';
import PermissionsDialog from './components/permissions.dialog';


function AddRole() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [formData, setFormData] = useState({
        name: "",
        persianName: "",
        permisionsList: [],
        menusList: [],
    });

    const [errors, setErrors] = useState({});
    const [isDisabled, setIsDisabled] = useState(false);


    const [permisionsList, setPermisionsList] = useState();
    const [menusList, setMenusList] = useState();

    const nowDate = useMemo(() => new Date(), []);


    const validate = () => {
        const schema = getRoleSchema();
        const validation = schema.safeParse(formData);
        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((error) => {
                newErrors[error.path[0]] = error.message;
            });
            setErrors(newErrors);
            return false;
        }
        setErrors({});
        return true;
    };

    const handleTogglePermission = useCallback((permissionId) => {
        setFormData((prevData) => ({
            ...prevData,
            permisionsList: prevData.permisionsList.includes(permissionId)
                ? prevData.permisionsList.filter((id) => id !== permissionId)
                : [...prevData.permisionsList, permissionId],
        }));
    }, []);

    const handleToggleMenu = useCallback((menu) => {
        setFormData((prevData) => ({
            ...prevData,
            menusList: prevData.menusList.some((m) => m.id === menu.id)
                ? prevData.menusList.filter((m) => m.id !== menu.id)
                : [...prevData.menusList, menu],
        }));
    }, []);

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const schema = getRoleSchema();
        const fieldValidation = schema.shape[name].safeParse(value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldValidation.success ? undefined : fieldValidation.error.errors[0].message,
        }));
    };


    const getPermissions = useCallback(() => {
        permissionsList()
            .then(response => {
                setPermisionsList(response.data.data.list);
            })
            .catch(e => {
                dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
            })

        MenuListApi()
            .then(response => {
                setMenusList(response.data.data.list)
            })
            .catch(e => {
                dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
            })
    }, [dispatch, nowDate]);

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }

        getPermissions()
    }, [dispatch, getPermissions, navigate])

    const SubmitRoleBtn = (e) => {
        e.preventDefault();
        if (!validate()) return;
        setIsDisabled(true);
        createRole(formData.name, formData.persianName)
            .then(response => {
                getRolesList(formData.name, 1, 1)
                    .then(response2 => {
                        const addedRole = response2.data.data.list[0]
                        assignPerToRole(addedRole.id, formData.permisionsList)
                            .then(response3 => {
                                assignMenuToRole(addedRole.id, formData.menusList)
                                    .then(response4 => {
                                        dispatch(notification({ message: 'نقش با موفقیت افزوده شد', type: 'suc', id: nowDate.getSeconds() }))
                                        navigate('/roles-list');
                                    })
                                    .catch(e4 => {
                                        dispatch(notification({ message: e4.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
                                    })

                            })
                            .catch(e3 => {
                                dispatch(notification({ message: e3.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
                            })
                    })
                    .catch(e2 => {
                        dispatch(notification({ message: e2.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
                    })

            })
            .catch(e => {
                dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
            })
    }

    const isPermissionSelected = (item) => {
        return formData?.permisionsList.includes(item);
    };

    const isMenuSelected = id => {
        return formData?.menusList.some(item => item.id === id);
    };

    return (
        <div>
            <Navbar displayMenu />
            <div className="w-full flex items-start">
                <div className="hidden md:block">
                    <Sidebar />
                </div>

                <div className="w-full p-8 max-md:p-4">
                    <h2 className="text-xl font-bold text-gray-800 mb-12">افزودن نقش</h2>
                        <div className="w-full border border-slate-200 max-md:border-none rounded-lg overflow-hidden p-4">
                            <form onSubmit={SubmitRoleBtn} className="flex flex-col items-start gap-y-4 max-w-5xl mx-auto">


                            <div className="flex max-md:flex-col items-start w-full gap-x-12">
                                <FormField
                                    id="name"
                                    label="نام نقش"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    error={errors.name}
                                    description="نام نقش"
                                    required
                                />

                                <FormField
                                    id="persianName"
                                    label="نام فارسی نقش"
                                    name="persianName"
                                    value={formData.persianName}
                                    onChange={handleInputChange}
                                    error={errors.persianName}
                                    description="نام فارسی نقش"
                                    required
                                />
                            </div>

                            <PermissionsDialog data={permisionsList} selectedItems={formData.permisionsList} onTogglePermission={(permission) => handleTogglePermission(permission)} />

                            <ScrollArea dir='rtl' className='h-auto max-lg:h-[256px] w-full max-lg:rounded-md max-lg:border max-lg:p-4'>
                                <div className="grid grid-cols-4 max-lg:grid-cols-1 items-center gap-2 w-full">
                                    {formData?.permisionsList.map(permission => (
                                        <Button variant='outline' type='button' key={permission} className='!m-0' onClick={() => handleTogglePermission(permission)}>
                                            {isPermissionSelected(permission) ? <XIcon className='w-4 h-4 shrink-0' /> : null}
                                            {permission.split('_v1_')[1]}
                                        </Button>
                                    ))}
                                </div>
                            </ScrollArea>

                            <MenuListDialog data={menusList} selectedItems={formData.menusList} onToggleMenu={(menu) => handleToggleMenu(menu)} />

                            <ScrollArea dir='rtl' className='h-auto max-lg:h-[256px] w-full max-lg:rounded-md max-lg:border max-lg:p-4'>
                                <div className="grid grid-cols-4 max-lg:grid-cols-1 items-center gap-2 w-full">
                                    {formData?.menusList.map(menu => (
                                        <Button variant='outline' type='button' key={menu.id} className='!m-0' onClick={() => handleToggleMenu(menu)}>
                                            {isMenuSelected(menu.id) ? <XIcon className='w-4 h-4 shrink-0' /> : null}
                                            {menu.name}
                                        </Button>
                                    ))}
                                </div>
                            </ScrollArea>

                            <Button className='!m-0 w-[200px] max-lg:w-full' type="submit" disabled={isDisabled}>
                                ثبت اطلاعات
                            </Button>
                        </form>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AddRole;