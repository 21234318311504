import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeBankState, getBanks, getUserInfo, updateBankBalance } from '../../api';
import { notification, userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

import { useQueryClient } from "@tanstack/react-query";
import BankDetailsModal from './components/bank-details.modal';
import { columns } from './components/columns';
import { DataTable } from './components/data-table';
import TableToolbar from './components/table-toolbar';

const CustomerList = memo(function CustomerList() {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [banks, setBanks] = useState([])
    const [page, setPage] = useState(1)
    const [totalPage, setTotalPage] = useState()
    const [pageSize, setPageSize] = useState(10)
    const [bankName, setBankName] = useState("");
    const [isChangingStatus, setIsChangingStatus] = useState('');
    const [isUpdating, setIsUpdating] = useState('');
    const [isOpen, setIsOpen] = useState(null);

    const [loading, setLoading] = useState(true)

    const handleToggleDetailsModal = useCallback((bankId) => {
        if (isOpen) {
            setIsOpen(null);
        } else {
            setIsOpen(bankId);
        }
    }, [isOpen])

    const getList = useCallback((name = '', _page = page, _pageSize = pageSize) => {
        setLoading(true);
        getBanks(name, _page, _pageSize)
            .then(response => {
                setBanks(response.data.data.list);
                setPage(response.data.data.page);
                setTotalPage(response.data.data.totalPage);
                setIsChangingStatus('');
                setLoading(false)
            })
            .catch(e => {
                console.log(e);
                setIsChangingStatus('');
                setLoading(false)
            })
    }, [page, pageSize])

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }

    }, [dispatch, navigate])

    const handleEditBank = useCallback((bankId) => {
        navigate("/edit-bank?bankId=" + bankId)
    }, [navigate])

    const changeBankStatus = useCallback(async (id) => {
        setIsChangingStatus(id);
        const response = await changeBankState(id);
        if (response.data.isSuccess) {
            setIsChangingStatus('');
            dispatch(notification({ message: 'وضعیت با موفقیت تغییر کرد', type: 'suc', id: new Date().getSeconds() }))
            setBanks(prev => prev.map(bank => {
                if (bank.id === id) {
                    return {
                        ...bank,
                        isActive: !bank.isActive
                    }
                }
                return bank;
            }))
            queryClient.invalidateQueries(['active-banks']);
        } else {
            setIsChangingStatus('');
            dispatch(notification({ message: 'خطا در تغییر وضعیت', type: 'err', id: new Date().getSeconds() }))
        }

    }, [dispatch, queryClient])

    const updateBalance = useCallback(async (id) => {
        setIsUpdating(id);
        try {
            const response = await updateBankBalance(id);
            if (response.data.isSuccess) {
                dispatch(notification({ message: 'موجودی با موفقیت بروزرسانی شد', type: 'suc', id: new Date().getSeconds() }))
                setBanks(prev => prev.map(bank => {
                    if (bank.id === id) {
                        return {
                            ...bank,
                            accountBalanceAmount: response.data.data.accountBalance,
                            updateDate: response.data.data.updatedDate,
                        };
                    }
                    return bank;
                }))
            }
        } catch (error) {
            dispatch(notification({ message: error.response.data.Error.Message, type: 'err', id: new Date().getSeconds() }));
            console.log(error);
        } finally {
            setIsUpdating('');
        }
    }, [dispatch]);

    const updateURLParams = useCallback((name, pageValue, pageSizeValue) => {
        const params = new URLSearchParams({
            bankName: name,
            page: pageValue.toString(),
            pageSize: pageSizeValue.toString(),
        });
        navigate(`?${params.toString()}`, { replace: true });
    }, [navigate]);

    const handlePageSizeChange = useCallback((value) => {
        setPageSize(value);
        setPage(1);
        updateURLParams(bankName, 1, value);
    }, [bankName, updateURLParams]);

    const handlePageChange = useCallback((newPage) => {
        setPage(newPage);
        updateURLParams(bankName, newPage, pageSize);
    }, [bankName, pageSize, updateURLParams]);

    const handleSubmitFilters = useCallback((e) => {
        e.preventDefault();
        setPage(1);
        updateURLParams(bankName, 1, pageSize);
    }, [bankName, pageSize, updateURLParams]);

    const removeFilter = useCallback((filterKey) => {
        const params = new URLSearchParams(location.search);
        params.delete(filterKey);

        switch (filterKey) {
            case "bankName":
                setBankName("");
                break;
            default:
                break;
        }

        navigate(`?${params.toString()}`, { replace: true });
    }, [navigate, location.search]);

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const banknameFromParams = params.get("bankName") || "";
        const pageFromParams = parseInt(params.get("page")) || 1;
        const pageSizeFromParams = parseInt(params.get("pageSize")) || 10;

        setBankName(banknameFromParams);
        setPage(pageFromParams);
        setPageSize(pageSizeFromParams);

        getList(
            banknameFromParams,
            pageFromParams,
            pageSizeFromParams,
        );
    }, [location.search, getList]);

    return (
        <div>
            <Navbar displayMenu />
            <div className="w-full flex items-start">
                <div className="hidden md:block">
                    <Sidebar />
                </div>
                <div className="w-full p-8 max-md:p-4 overflow-x-auto">

                    <h2 className="text-xl font-bold text-gray-800 mb-12">فهرست  بانک‌ها</h2>
                    <div className="w-full bg-slate-100 border border-slate-200 rounded-lg overflow-hidden">
                        <TableToolbar
                            onSearch={handleSubmitFilters}
                            bankName={bankName}
                            setBankName={setBankName}
                            onRemoveFilter={removeFilter}
                        />

                        <div className="rounded-lg overflow-hidden border-t border-gray-300">
                            <DataTable
                                columns={columns}
                                data={banks}
                                page={page}
                                pageSize={pageSize}
                                totalPage={totalPage}
                                onPageChange={handlePageChange}
                                loading={loading}
                                toggleBankStatus={changeBankStatus}
                                onPageSizeChange={handlePageSizeChange}
                                onGetBankDetails={handleToggleDetailsModal}
                                onEditBank={handleEditBank}
                                onUpdateBalance={updateBalance}
                                isUpdating={isUpdating}
                                isChangingStatus={isChangingStatus}
                            />
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <BankDetailsModal isOpen={isOpen} onOpenChange={handleToggleDetailsModal} onEditBank={handleEditBank} /> : null}
        </div>
    );
});

export default CustomerList;