import { useCallback, useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getRolesList, getUserInfo } from '../../api';
import { userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';

import { useQuery } from '@tanstack/react-query';
import { columns } from './components/columns';
import { DataTable } from './components/data-table';
import TableToolbar from './components/table-toolbar';

function RoleList() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const params = new URLSearchParams(location.search);

    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [roleName, setRoleName] = useState("");

    const { data, isFetching } = useQuery({
        queryKey: ['roles',  params.get("roleName") || "", page, pageSize],
        queryFn: () => getRolesList( params.get("roleName") || "", page, pageSize),
    });

    const updateURLParams = useCallback((name , pageValue = page, pageSizeValue = pageSize) => {
        const params = new URLSearchParams({
            roleName: name,
            page: pageValue.toString(),
            pageSize: pageSizeValue.toString(),
        });
        navigate(`?${params.toString()}`, { replace: true });
    }, [page, pageSize, navigate]);

    const handlePageSizeChange = useCallback((value) => {
        setPageSize(value);
        setPage(1);
        updateURLParams(roleName, 1, value);
    }, [roleName, updateURLParams]);

    const handlePageChange = useCallback((newPage) => {
        setPage(newPage);
        updateURLParams(roleName, newPage, pageSize);
    }, [roleName, pageSize, updateURLParams]);

    const handleSubmitFilters = useCallback((e) => {
        e.preventDefault();
        setPage(1);
        updateURLParams(roleName, 1, pageSize);
    }, [roleName, pageSize, updateURLParams]);

    const removeFilter = useCallback((filterKey) => {
        const params = new URLSearchParams(location.search);
        params.delete(filterKey);

        switch (filterKey) {
            case "roleName":
                setRoleName("");
                break;
            default:
                break;
        }

        navigate(`?${params.toString()}`, { replace: true });
    }, [navigate, location.search]);


    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const roleNameFromParams = params.get("roleName") || "";
        const pageFromParams = parseInt(params.get("page")) || 1;
        const pageSizeFromParams = parseInt(params.get("pageSize")) || 10;

        setRoleName(roleNameFromParams);
        setPage(pageFromParams);
        setPageSize(pageSizeFromParams);

    }, [location.search]);

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }

    }, [dispatch, navigate])

    return (
        <>
            <div>
                <Navbar displayMenu />
                <div className="w-full flex items-start">
                    <div className="hidden md:block">
                        <Sidebar />
                    </div>
                    <div className="w-full p-8 max-md:p-4 overflow-x-auto">
                        <h2 className="text-xl font-bold text-gray-800 mb-12">فهرست  نقش‌ها</h2>
                        <div className="w-full bg-slate-100 border border-slate-200 rounded-lg overflow-hidden">
                            <TableToolbar
                                onSearch={handleSubmitFilters}
                                roleName={roleName}
                                setRoleName={setRoleName}
                                onRemoveFilter={removeFilter}
                            />

                            <div className="rounded-lg overflow-hidden border-t border-gray-300">
                                <DataTable
                                    columns={columns}
                                    data={data?.data.data.list}
                                    page={page}
                                    pageSize={pageSize}
                                    totalPage={data?.data.data.totalPage}
                                    onPageChange={handlePageChange}
                                    loading={isFetching}
                                    onPageSizeChange={handlePageSizeChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default RoleList;