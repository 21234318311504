import { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getUserInfo, showBank, updateBank } from '../../api';
import { FormField } from "../../components/Customers/form-field";
import { Button } from "../../components/ui/button";
import { getBankSchema } from "../../lib/schemas";
import { notification, userInfo } from '../../state/action';
import Navbar from '../Navbar';
import Sidebar from '../Sidebar';


function EditBank() {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams();
    const [isDisabled, setIsDisabled] = useState(false);
    const [errors, setErrors] = useState({});
    const nowDate = new Date();
    const [formData, setFormData] = useState({
        name: "",
        transactionLimit: "",
    });

    useEffect(() => {
        if (sessionStorage.getItem('access_token')) {
            getUserInfo()
                .then(response => {
                    dispatch(userInfo(response.data.data))
                })
                .catch(e => {
                    navigate('/login');
                })
        } else {
            navigate('/login');
        }
    }, [])

    const validate = () => {
        const schema = getBankSchema();
        const validation = schema.safeParse(formData);
        if (!validation.success) {
            const newErrors = {};
            validation.error.errors.forEach((error) => {
                newErrors[error.path[0]] = error.message;
            });
            setErrors(newErrors);
            return false;
        }
        setErrors({});
        return true;
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;

        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
        }));

        const schema = getBankSchema();
        const fieldValidation = schema.shape[name].safeParse(value);

        setErrors((prevErrors) => ({
            ...prevErrors,
            [name]: fieldValidation.success ? undefined : fieldValidation.error.errors[0].message,
        }));
    };

    const handleSubmitBank = e => {
        e.preventDefault();
        const bankId = searchParams.get('bankId');
        if (!bankId) return;

        if (!validate()) return;
        setIsDisabled(true);
        const { name, transactionLimit } = formData;
        updateBank(bankId, name, transactionLimit)
            .then(response => {
                dispatch(notification({ message: 'اطلاعات بانک با موفقیت بروزرسانی شد', type: 'suc', id: nowDate.getSeconds() }))
                navigate('/banks-list');
                setIsDisabled(false);
            })
            .catch(e => {
                dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))
                setIsDisabled(false);
            })
    }

    useEffect(() => {
        if (searchParams.get('bankId')) {
            showBank(searchParams.get('bankId'))
                .then(response => {
                    setFormData({
                        name: response.data.data.name,
                        transactionLimit: response.data.data.tranasactionLimit
                    })
                })
                .catch(e => {
                    dispatch(notification({ message: e.response.data.Error.Message, type: 'err', id: nowDate.getSeconds() }))

                })
        }
    }, [])

    return (
        <div>
            <Navbar displayMenu />
            <div className="w-full flex items-start">
                <div className="hidden md:block">
                    <Sidebar />
                </div>
                <div className="w-full p-8 max-md:p-4">
                    <h2 className="text-xl font-bold text-gray-800 mb-12">ویرایش اطلاعات بانک</h2>
                    <div className="w-full border border-slate-200 max-md:border-none rounded-lg overflow-hidden p-4">
                        <form onSubmit={handleSubmitBank} className="flex flex-col items-start gap-y-4 max-w-4xl mx-auto">


                            <div className="flex max-md:flex-col items-start w-full gap-x-12">
                                <FormField
                                    id="name"
                                    label="نام بانک"
                                    name="name"
                                    value={formData.name}
                                    onChange={handleInputChange}
                                    error={errors.name}
                                    description="نام بانک"
                                    required
                                />

                                <FormField
                                    id="transactionLimit"
                                    label="محدودیت تراکنش"
                                    name="transactionLimit"
                                    value={formData.transactionLimit}
                                    onChange={handleInputChange}
                                    error={errors.transactionLimit}
                                    description="محدودیت تراکنش"
                                    required
                                    type="number"
                                    inputMode="decimal"
                                />
                            </div>
                            <Button className='!m-0' type="submit" disabled={isDisabled}>
                                ویرایش اطلاعات
                            </Button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditBank;